// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
import debounce from './includes/debounce';

jQuery(document).ready(function($) {

    $("html").removeClass("no-js");

    $('.gfield_select').select2({
        minimumResultsForSearch: -1
    });

    if ($('.wp-block-image-slider').length) {
        $('.wp-block-image-slider').slick({
            arrows: false,
            dots: false,
            centerMode: true,
            centerPadding: '25%',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 3,
        });
    };

    /* Enhance Mouse Focus */

    var enhanceMouseFocusActive = false;
    var enhanceMouseFocusEnabled = false;
    var enhanceMouseFocusElements = jQuery();
    var enhanceMouseFocusNewElements = jQuery();

    function enhanceMouseFocusUpdate() {
        if (enhanceMouseFocusEnabled) {
            // add any new focusable elements
            enhanceMouseFocusNewElements = jQuery('button, input[type="submit"], input[type="button"], [tabindex]:not(input, textarea)').not(enhanceMouseFocusElements);

            enhanceMouseFocusElements = enhanceMouseFocusElements.add(enhanceMouseFocusNewElements);

            // if an element gets focus due to a mouse click, prevent it from keeping focus
            enhanceMouseFocusNewElements.mousedown(function() {
                enhanceMouseFocusActive = true;
                setTimeout(function() {
                    enhanceMouseFocusActive = false;
                }, 50);
            }).on('focus', function() {
                if (enhanceMouseFocusActive) {
                    jQuery(this).blur();
                }
            });
        }
    }

    function initEnhanceMouseFocus() {
        enhanceMouseFocusElements = jQuery();
        enhanceMouseFocusEnabled = true;

        enhanceMouseFocusUpdate();

        // update focusable elements on Gravity Forms render
        jQuery(document).on('gform_post_render', function() {
            enhanceMouseFocusUpdate();
        });
    }
    initEnhanceMouseFocus();

    /* Popup Boxes */
    function initPopupBoxes($) {
        var throttledUpdatePopupBoxPosition = debounce(updatePopupBoxPosition, 100);
        var body = $('body');
        var boxWrap = $('<div class="popup-box" />');
        var boxContent = $('<div class="popup-box__content" />').appendTo(boxWrap);
        var box = $('<div class="popup-box__box" tabindex="-1" />').appendTo(boxContent);
        var boxEnd = $('<div class="popup-box__end" tabindex="0" />').appendTo(boxContent);
        boxEnd.focus(function() {
            box.focus();
            return false;
        });

        function openPopupBox(content, contentAppended) {
            var scrollTop = $(window).scrollTop();

            box.empty();
            box.append(content);

            typeof contentAppended === 'function' && contentAppended();

            positionPopupBox();

            enhanceMouseFocusUpdate();

            boxWrap.addClass('popup-box_active');

            boxWrap.data('lastFocus', $(document.activeElement));
            box.focus();
            $(window).scrollTop(scrollTop);
        }

        function closePopupBox() {
            var scrollTop = $(window).scrollTop();

            boxWrap.removeClass('popup-box_active');

            boxWrap.data('lastFocus').focus();
            $(window).scrollTop(scrollTop);
        }

        function positionPopupBox() {
            var margin = $(window).scrollTop() + 50;
            var windowHeight = $(window).height();
            var containerHeight = boxWrap.height();
            var boxHeight = box.height();

            box.css('margin-top', '');

            if (windowHeight > boxHeight + 100) {
                margin += ((windowHeight - boxHeight) / 2) - 50;
            }
            margin = Math.min(margin, containerHeight - boxHeight - 50);
            box.css('margin-top', margin + 'px');
        }

        function updatePopupBoxPosition() {
            if (box.outerHeight(true) > boxWrap.height() - 50) {
                positionPopupBox();
            }
        }

        function createPopupBox(id) {
            var content = $('[data-popup-box-content="' + id + '"]').clone(true, true).removeAttr('data-popup-box-content');
            var closeButton = $('<button type="button" class="popup-box__close"><span class="screen-reader-text">Close</span></button>');

            closeButton.click(closePopupBox);
            closeButton.prependTo(content);

            openPopupBox(content);
        }

        function createPopupGalleryBox(id, slide) {
            slide = (typeof slide === 'number') ? slide : 0; // default to first if no valid slide index is provided

            var content = $('<div class="box-slider" />');
            var slider = $('<div class="box-slider__slider" />').appendTo(content);
            var closeButton = $('<button type="button" class="popup-box__close popup-box__close_alt"><span class="screen-reader-text">Close</span></button>');

            // build slide for each image in the source element
            $('[data-popup-gallery-box-slides="' + id + '"]').find('[data-src]').each(function() {
                var slide = $('<div class="box-slider__slide"></div>').appendTo(slider);
                var src = $(this).attr('data-src');
                var caption = ($(this).is('[data-caption]')) ? $(this).attr('data-caption') : '';

                // console.log($(this));
                $('<div class="box-slider__image" />').css('background-image', 'url("' + src + '")').appendTo(slide);
                if (caption != '') {
                    $('<p class="box-slider__caption" />').html(caption).appendTo(slide);
                }


                console.log(src);



            });

            closeButton.click(closePopupBox);
            closeButton.prependTo(content);

            openPopupBox(content, function() {
                // initialize slick slider if there is more than one slide in the container
                if (slider.find('.box-slider__slide').length > 1) {
                    slider.slick({
                        accessibility: false,
                        arrows: false,
                        initialSlide: slide,
                        speed: 400
                    });

                    $('<button type="button" class="box-slider__arrow box-slider__arrow_prev" title="Previous"><span class="screen-reader-text">Previous</span></button>').click(function() {
                        slider.slick('slickPrev');
                    }).appendTo(content);
                    $('<button type="button" class="box-slider__arrow box-slider__arrow_next" title="Next"><span class="screen-reader-text">Next</span></button>').click(function() {
                        slider.slick('slickNext');
                    }).appendTo(content);
                }
            });
        }

        function preloadPopupGalleryBoxImages() {
            $('[data-popup-gallery-box-slides] [data-src]').each(function() {
                $('<img />').attr('src', $(this).attr('data-src')).load(function() {
                    $(this).remove();
                });
            });
        }


        $('.site-container').prepend(boxWrap);

        $(window).load(preloadPopupGalleryBoxImages);

        $(window).resize(throttledUpdatePopupBoxPosition);
        $(document).on('gform_post_render', positionPopupBox);

        boxWrap.click(function(e) {
            if ($(e.target).hasClass('popup-box') || $(e.target).hasClass('popup-box__content')) {
                closePopupBox();
            }
        });

        $(document).keydown(function(e) {
            if (boxWrap.hasClass('popup-box_active')) {
                if (e.which === 27) {
                    closePopupBox();
                } else if (boxContent.find('.box-slider__slider.slick-slider').length > 0) {
                    if (e.which === 37) {
                        boxContent.find('.box-slider__slider.slick-slider').slick('slickPrev');
                    } else if (e.which === 39) {
                        boxContent.find('.box-slider__slider.slick-slider').slick('slickNext');
                    }
                }
            }
        });

        $('[data-popup-box]').click(function() {
            createPopupBox($(this).attr('data-popup-box'));
        });
        $('[data-popup-gallery-box]').click(function() {
            if ($(this).is('[data-start]')) {
                createPopupGalleryBox($(this).attr('data-popup-gallery-box'), parseInt($(this).attr('data-start')));
            } else {
                createPopupGalleryBox($(this).attr('data-popup-gallery-box'));
            }
        });
    }
    initPopupBoxes($);



    /* Init NavSub Drop */
    function initNavDrop() {
        let m = window.matchMedia("(max-width: 950px)");
        var target = jQuery(".menu-item-has-children");
        target.find(".sub-menu").attr("tabindex", "-1");

        if (!m.matches) {
            // Remove Event listener.
            target.unbind("click tap");
            target.hover(
                function() {
                    jQuery(this).find(".sub-menu").addClass("sub-menu_active");
                },
                function() {
                    jQuery(this).find(".sub-menu").removeClass("sub-menu_active");
                }
            );
            // This helps with tab activation features.
            target.focusin(function() {
                jQuery(this).find(".sub-menu").addClass("sub-menu_active");
            });
            target.focusout(function() {
                jQuery(this).find(".sub-menu").removeClass("sub-menu_active");
            });
        } else if (m.matches) {
            // Remove Event listener.
            target.unbind("mouseenter mouseleave");
            target.on("click tap", function(e) {
                // This disables href until later on.
                let firstElementA = jQuery(this).children();
                firstElementA.attr("data-temp", jQuery(this).find("a").attr("href"));
                firstElementA.removeAttr("href"); //The link is not clickable anymore
                if (!jQuery(this).hasClass("important")) {
                    jQuery(this).addClass("important");
                    var subMenu = jQuery(this).find(".sub-menu");
                    var subMenuActive = subMenu.hasClass("sub-menu_active");
                    subMenu.addClass("sub-menu_active");
                    setTimeout(function() {
                        var subMenuPos = subMenu.offset();
                        jQuery(".full-menu__back").css({
                            marginTop: subMenuPos.top + 20
                        });
                        jQuery(".full-menu__back").css({
                            opacity: 1
                        });
                    }, 400);
                    if (subMenuActive) {
                        subMenu.removeClass("sub-menu_active");
                    }
                } else {
                    // Re-establish href link.
                    firstElementA.attr("href", jQuery(this).find("a").attr("data-temp"));
                }
                jQuery(".full-menu-back_hidden").removeClass("full-menu-back_hidden");
                jQuery(".menu-item").addClass("menu-item_deactive");
                jQuery(this).removeClass("menu-item_deactive");
                jQuery(this).addClass("menu-item_active");
                jQuery(this)
                    .find(".sub-menu")
                    .find(".menu-item")
                    .removeClass("menu-item_deactive");
                jQuery(this)
                    .find(".sub-menu")
                    .find(".menu-item")
                    .addClass("menu-item_active");
            });
            // This resets the mobile nav orignal settings.
            jQuery(".full-menu__back").on("click tap", function() {
                jQuery(".menu-item").removeClass("menu-item_active");
                jQuery(".menu-item").removeClass("menu-item_deactive");
                jQuery(".menu-item").removeClass("important");
                jQuery(".sub-menu_active").removeClass("sub-menu_active");
                jQuery(".full-menu-back").addClass("full-menu-back_hidden");
            });
        }
    }
    initNavDrop();
    var efficentNavDrop = debounce(initNavDrop, 250);
    jQuery(window).on("resize", efficentNavDrop);

    /* Mobile Menu */
    function mobNavTrigger($) {
        var mobNavToggle = $(".full-menu__trigger");
        var topBar = $(".full-menu");
        var body = $("body");
        var bodyOverlay = $(".background_overlay_content");
        // This sets full menu header to the header height.
        function headerResize() {
            var navHeight = $("header").innerHeight();
            $(".full-menu__top").css("height", navHeight + "px");
        }
        // open Function.
        function openMenu() {
            topBar.addClass("full-menu_active");
            body.addClass("disable");
            bodyOverlay.addClass("background_overlay_content_active");
            setTimeout(function() {
                body.addClass("disable_fixed");
            }, 400);
            body.data("lastScroll", $(window).scrollTop());
        }
        // Close Function.
        function closeMenu() {
            topBar.removeClass("full-menu_active");
            body.removeClass("disable");
            body.removeClass("disable_fixed");
            bodyOverlay.removeClass("background_overlay_content_active");
            $(window).scrollTop(body.data("lastScroll"));
        }
        // Close Menu on Key Press Escape.
        $(document).keydown(function(e) {
            var isOpen = $(".full-menu").hasClass("full-menu_active");
            if (isOpen) {
                if (e.which === 27) {
                    closeMenu();
                }
            }
        });
        // Trigger the Mobile Takeover
        mobNavToggle.click(function() {
            var isOpen = topBar.hasClass("full-menu_active");
            if (isOpen) {
                closeMenu();
            } else {
                headerResize();
                openMenu();
            }
        });
        $(window).resize(function() {
            headerResize();
            if (window.innerWidth > 850) {
                closeMenu();
            }
        });
    }
    mobNavTrigger($);

    /* Background Parallax */
    function initBgParallax() {
        var throttledUpdateBG = debounce(updateBackgroundPosition, 2);
        var parallaxSections = [];
        var sectionHeight, sectionTop, scrollTop;
        // Store Data..
        $('[data-parallax="section"]').each(function() {
            parallaxSections.push({
                section: $(this),
                object: $(this).find('[data-parallax="object"]'),
                bg: $(this).find('[data-parallax="bg"]'),
                image: $(this).find('[data-parallax="image"]'),
                content: $(this).find('[data-parallax="content"]'),
                contentSlow: $(this).find('[data-parallax="content-slow"]'),
                contentFast: $(this).find('[data-parallax="content-fast"]'),
            });
        });

        function updateBackgroundPosition() {
            if (window.innerWidth > 650) {
                $.each(parallaxSections, function(i, v) {
                    sectionHeight = v.section.outerHeight();
                    sectionTop = v.section.offset().top;
                    scrollTop = $(window).scrollTop();

                    //v.object.css("object-position", 0 + ((sectionTop - scrollTop) * 0.01 + "px"));
                    let calc = (sectionTop - scrollTop) * 0.01;
                    v.object.css("transform", `translate3d(0, ${calc}px, 0`);
                    //v.bg.css("height", sectionHeight + "px");
                    v.bg.css(
                        "transform",
                        "scale(1.2) translate3d(0, " +
                        (sectionTop - scrollTop) * 0.08 +
                        "px, 0)"
                    );
                    /*v.image.css({
                    	"height":
                    			Math.min(
                    					sectionTop + sectionHeight,
                    					window.innerHeight +
                    					Math.max(0, (sectionHeight - window.innerHeight) * 0.5)) + "px"
                    });*/
                    v.content.css({
                        "transform": "translate3d(0, " + (scrollTop - sectionTop) * 0.05 + "px, 0)"
                    });

                    v.contentSlow.css({
                        "transform": "translate3d(0, " + (scrollTop - sectionTop) * 0.03 + "px, 0)",
                        //"margin-bottom": ((scrollTop - sectionTop) * .03) + 'px'
                    });
                    v.contentFast.css({
                        "transform": "translate3d(0, " + (scrollTop - sectionTop) * 0.08 + "px, 0)",
                        //"margin-bottom": ((scrollTop - sectionTop) * .08) + 'px'
                    });
                    v.image.css({
                        "transform": "translate3d(0, " + (sectionTop - scrollTop) * 0.05 + "px, 0)"
                    });
                });
            } else {
                $.each(parallaxSections, function(i, v) {
                    v.bg.css("height", "");
                    v.bg.css("transform", "");

                    v.image.css("height", "");
                    v.image.css("transform", "");

                    v.content.css("transform", "");
                });
            }
        }
        throttledUpdateBG();
        $(window).on("load scroll resize", throttledUpdateBG);
    }
    initBgParallax();

    // User perference in lightmode.
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: light)");
    if (prefersDarkScheme.matches) {
        document.body.classList.add("dark-mode"); // Normally this would be set to light-mode.
        $("[data-lightmode]").attr("data-lightmode", "dark"); // Normally this would be set to light.
    } else {
        document.body.classList.add("dark-mode");
        $("[data-lightmode]").attr("data-lightmode", "dark");
    }

    // This is a custom lazy loader that uses Intersection Observer, Web worker & Blob image creation resizing.
    const loaderElements = document.querySelectorAll(".animate-me");
    let observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add("fancy");
                    observer.unobserve(entry.target);
                } else {
                    entry.target.classList.remove("fancy");
                }
            });
        }, {
            rootMargin: "0px 0px -50px 0px"
        }
    );
    loaderElements.forEach((element) => {
        observer.observe(element);
    });

});

document.addEventListener('scroll', function(e) {
    if (document.body.getBoundingClientRect().top < -150 && document.body.classList.contains('scrolled') === false) {
        document.body.classList.add('scrolled');
    } else if (document.body.getBoundingClientRect().top >= -150 && document.body.classList.contains('scrolled')) {
        document.body.classList.remove('scrolled');
    }
});